.all-items {
  text-align: center;
}

.items-page .filter-style h4 {
  background-color: rgb(247, 93, 93);
  color: rgb(255, 255, 255);
  text-align: right;
  padding-right: 5px;
}

.items-page .dropdown-group {
  display: flex;
}

.dropdown {
  margin-right: 15px;
}

@media only screen and (max-width: 600px) {
  .items-page .dropdown-group {
    display: flex;
  }
  .items-page .filter-style h4 {
    width: 100px;
  }
}
