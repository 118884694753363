.Compliance {
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  color: rgb(167, 167, 167);
  padding-bottom: 3%;
}

.Compliance h1 {
  font-size: 10vw;
  font-family: "Lato", sans-serif;
  color: rgb(167, 167, 167);
  padding-top: 25px;
}
.Compliance .compliance-line {
  width: 120px;
  border-bottom: 3px solid rgb(167, 167, 167);
}

.Compliance .compliance-breakline {
  border-bottom: 2px solid rgb(13, 175, 159);
}
.Compliance h2 {
  font-family: "Lato", sans-serif;
  font-weight: bold;
  padding-top: 80px;
}
.Compliance .text-change {
  color: rgb(13, 175, 159);
}
.Compliance .capital-text {
  text-transform: uppercase;
}
.Compliance .uae-underline {
  width: 60px;
  border-bottom: 3px solid rgb(13, 175, 159);
}

.Compliance .compliance-contents {
  padding-top: 40px;
}
.Compliance .compliance-text {
  font-size: 18px;
}
.Compliance .card {
  border: none !important;
  border-radius: 0px !important;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}
.Compliance .cardtitle {
  font-size: 25px;
  font-weight: bold;
  padding-top: 10px;
}
.Compliance .card-underline {
  width: 12%;
  border-bottom: 3px solid rgb(247, 93, 93);
}
.Compliance .card-body {
  padding: 0px !important;
}
.Compliance .card-border {
  /* padding: 1.25rem; */
  padding: 10px 18px 10px 18px;
}
.Compliance .mini-icon {
  width: 70px;
  padding: 5px 0px 5px 0px;
  background-color: rgb(247, 93, 93);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
