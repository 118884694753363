.Contact {
  width: 95%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 8.5%;
}

.Contact h1 {
  color: rgb(163, 163, 163);
}

.Contact .underline-contact {
  width: 50px;
  border-bottom: 3px solid rgb(247, 93, 93);
}

.Contact .form {
  padding-right: 30px;
}

.Contact .form .form-control {
  background-color: rgb(232, 232, 232) !important;
  border: none !important;
}

.Contact .form .btn {
  background-color: rgb(247, 93, 93);
  border: none;
}
.Contact .form .btn-holder .btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.Contact .contact-line {
  height: 400px;
  border-right: 3px solid rgb(163, 163, 163);
}

.Contact .centre-container {
  text-align: center;
  padding-top: 70px;
}

@media (max-width: 576px) {
  .Contact .contact-line {
    display: none;
  }
}

@media (max-width: 768px) {
  .Contact .contact-line {
    display: none;
  }
}
