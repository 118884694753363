.NavBackend {
  width: 100%;
  margin-bottom: 2%;
}

.NavBackend a {
  color: rgb(95, 95, 95) !important;
  font-family: "proxima-nova", sans-serif;
  font-size: 18px;
  padding: 0 0 0 10px !important;
}
.NavBackend a:hover {
  text-decoration: none;
  color: rgb(247, 93, 93) !important;
}

.NavBackend .navbar-toggler {
  margin-top: 15px;
  margin-left: auto;
  border: 5px solid !important;
  border-radius: 4% !important;
  padding: 4px 6px !important;
  margin-bottom: 50px !important;
  color: rgb(211, 211, 211) !important;
}
.NavBackend button:focus {
  outline: none !important;
}

.NavBackend .requests-button {
  background-color: rgb(247, 93, 93);
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  border-radius: 2px;
  box-shadow: none !important;
  border: none !important;
}

.NavBackend .requests-style {
  color: rgb(255, 255, 255) !important;
}

.NavBackend .requests-button a:hover {
  color: rgb(255, 255, 255) !important;
}

.NavBackend .requests-button a {
  padding-left: 5px;
}

input {
  border: 1px solid rgb(95, 95, 95);
  border-radius: 5px;
  padding: 6px 0 6px 10px;
  width: 300px;
}
