.Signup .btn {
  background-color: rgb(247, 93, 93) !important;
  border: none !important;
}

.Signup .btn a {
  color: white;
}

.Signup .btn a :hover {
  color: white;
  text-decoration: underline;
}

.Signup {
  background-color: gray;
  padding: 30px;
  font-family: "proxima-nova", sans-serif;
  margin-top: 60px;
  margin-right: 100px;
}

.Signup label {
  color: white;
}

@media only screen and (max-width: 768px) {
  .Login {
    margin-top: 20px;
  }

  .Signup {
    margin-right: 0px;
  }

  .Signup {
    margin-top: 29px;
    margin-bottom: 20px;
  }
}
