.Footer {
  margin-top: 0;
  background-color: rgb(235, 235, 235);
  display: flex;
  flex-wrap: nowrap;
  height: 65px;
  width: 100%;
}
.Footer img {
  width: 160px;
  margin-top: 3px;
  margin-left: 22px;
  margin-bottom: -9px;
}

.Footer p {
  margin-left: 22px;
  font-size: 10px;
  color: rgb(128, 127, 127);
  margin-bottom: 0px !important;
}

.Footer a:hover {
  text-decoration: none;
}
