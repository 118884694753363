.Dashboard {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.Dashboard .sidebar {
  background-color: rgb(211, 211, 211);
  text-align: center;
  height: 800px;
}

.Dashboard .welcome {
  text-align: center;
  padding: 18px;
}

.Dashboard .dashboard-buttons {
  text-align: center;
  margin-bottom: 33%;
}

.Dashboard .dashboard-logout {
  padding: 8px 35px;
  margin: 8px 35px;
  font-size: 16px;
  background-color: rgb(247, 93, 93);
  color: rgb(255, 255, 255);
  border: none !important;
  width: 50% !important;
}

.Dashboard .dashboard-btn {
  text-align: center;
  padding: 8px 35px;
  margin: 8px 35px;
  font-size: 16px;
  background-color: rgb(13, 175, 159);
  color: rgb(255, 255, 255);
  border: none !important;
  width: 80%;
}

.Dashboard .react-icons {
  color: rgb(247, 93, 93);
  font-size: 8em;
  margin: 10px;
  margin-left: 160px;
}

.Dashboard .profile-image {
  border: 5px solid rgba(239, 239, 239, 0.5);
  margin-bottom: 60px;
}

@media only screen and (max-width: 600px) {
  .Dashboard .sidebar {
    height: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .Dashboard .dashboard-logout {
    width: 50%;
  }

  .Dashboard .dashboard-btn {
    width: 50%;
  }

  .Dashboard .sidebar {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .Dashboard .dashboard-buttons {
    text-align: center;
    margin-bottom: 15%;
  }

  .Dashboard .dashboard-btn {
    text-align: center;
    font-size: 3vw;
    width: 60%;
  }
  .Dashboard .dashboard-logout {
    padding: 8px 12px;
    font-size: 3vw;
  }
  .Dashboard .profile-image {
    margin-bottom: 40px;
  }
  .Dashboard .Row {
    display: flex;
    flex-direction: column;
  }
}
