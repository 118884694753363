body {
  margin: auto 0;
  font-family: "proxima-nova", sans-serif;
  background-image: url("./static/img/sensalaLogo.svg");
  background-repeat: no-repeat;
  background-position: 20px 20px;
  height: 100px;
}

code {
  font-family: "proxima-nova", sans-serif;
}

h1 {
  font-family: "lato", sans-serif;
}
