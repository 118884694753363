.Home {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 3%;
}
.Home .title-underline {
  width: 50px;
  border-bottom: 3px solid rgb(247, 93, 93);
}
.Home .title-colour {
  color: rgb(167, 167, 167);
  margin-top: 60px;
  padding-right: 40px;
}
.Home .text-colour {
  color: rgb(167, 167, 167);
  font-size: 20px;
}
.Home .line-animation {
  height: 440px;
  border-left: 3px solid rgb(211, 211, 211);
}
.Home .portal-style {
  height: 380px;
}
.Home .portal-style {
  position: relative;
  display: inline-block;
}
.Home .portal-style .overlay-text {
  position: absolute;
  z-index: 999;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 35%;
  text-align: center;
}
.Home .portal-style .overlay-text h3 {
  color: rgb(255, 255, 255);
  font-size: 65px;
  margin-bottom: 0px;
  line-height: 3rem;
}
.Home .portal-style .overlay-text h4 {
  color: rgb(255, 255, 255);
  font-size: 50px;
  padding-bottom: 5px;
}
.Home .portal-style .overlay-text .btn {
  padding: 8px 35px;
  font-size: 18px;
  background-color: rgb(255, 255, 255);
  color: rgb(247, 93, 93);
  border: none !important;
}
.Home .portal-style .overlay-text .btn:focus {
  outline: none;
}
.Home .portal-style .portal-image {
  border: 25px solid rgba(239, 239, 239, 0.5);
}
.Home .arrow-style {
  padding-right: 12px;
}
.Home .arrow-style p {
  font-size: 12px;
  text-align: center;
  color: rgb(163, 163, 163);
  margin-bottom: 5px !important;
}
.Home .arrow-style svg {
  margin-bottom: -8px;
}
.Home .break-line {
  border-bottom: 2px solid rgb(13, 175, 159);
}
.Home h3 {
  font-family: "Lato", sans-serif;
  font-weight: 700;
}
.Home .mini-title-style-red {
  color: rgb(247, 93, 93);
}

.Home .mini-title-style-white {
  color: rgb(255, 255, 255);
}
.Home .colour-wrapper {
  background-color: rgb(203, 202, 210);
  padding: 15px 20px 10px 20px;
  border-radius: 50%;
  width: 205px;
  height: 205px;
}
.FHome .collab-style-line {
  margin-top: 10px;
  width: 50px;
  border-bottom: 3px solid rgb(247, 93, 93);
}
.Home .mini-text-style {
  line-height: 1.3;
  margin-top: 15px;
  color: rgb(167, 167, 167);
  text-align: center;
}

.Home .service-container {
  background-color: rgba(167, 167, 167, 0.6);
}
.Home .service-container .scope-style-line {
  width: 50px;
  margin-top: 15px;
  border-bottom: 3px solid rgb(255, 255, 255);
}
.Home .service-container .text-wrap .services-text {
  color: rgb(255, 255, 255);
  padding-bottom: 20px;
  line-height: 2;
}
.Home .service-container .image-sizing {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Home .text-wrap {
  padding-left: 10px;
  padding-right: 10px;
}
.Home .text-wrap .btn {
  padding: 8px 35px;
  font-size: 18px;
  background-color: rgb(247, 93, 93);
  color: rgb(255, 255, 255);
  border: none !important;
}
.Home .text-wrap .btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.Home .text-wrap .btn:active {
  background-color: rgb(247, 93, 93) !important;
}

.Home .heading-style {
  color: rgb(167, 167, 167);
}
.Home .heading-style .green {
  color: rgb(13, 175, 159);
}
.Home .mini-title-style-green {
  width: 50px;

  border-bottom: 3px solid rgb(13, 175, 159);
}
.Home .green-background {
  background-color: rgb(13, 175, 159);
}
.Home .text-box-style {
  color: rgb(255, 255, 255);
}
.Home .text-box-style .text-box-underline {
  width: 60px;
  border-bottom: 3px solid rgb(255, 255, 255);
}
.Home .mini-text-box h5 {
  text-transform: uppercase;
}
.Home .image-fill {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding-right: 20px;
}

.Home .full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background-color: rgba(167, 167, 167, 0.3);
}
.Home .full-width h3 {
  color: rgb(247, 93, 93);
}
.Home .full-width .compliance-underline {
  width: 50px;
  border-bottom: 3px solid rgb(247, 93, 93);
}
.Home .full-width .compliance-box {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.Home .full-width .compliance-box .special-text {
  font-size: 30px;
  color: rgb(114, 114, 114);
}
.Home .full-width .compliance-box .special-text-bold {
  font-size: 50px;
  font-weight: 700;
  color: rgb(114, 114, 114);
}
.Home .full-width .compliance-box p {
  color: rgb(114, 114, 114);
}
.Home .full-width .compliance-box .compliance-btn {
  padding: 8px 35px;
  font-size: 18px;
  background-color: rgb(247, 93, 93);
  color: rgb(255, 255, 255);
  border: none !important;
}
.Home .full-width .compliance-box .compliance-btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.Home .contact-container {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}
.Home .contact-container-form {
  width: 87%;
  color: rgb(163, 163, 163);
  margin-right: auto;
  margin-left: auto;
}
.Home .contact-container .contact-styling {
  color: rgb(13, 175, 159);
}
.Home .contact-container-form .form-control {
  background-color: rgb(232, 232, 232) !important;
  border: none !important;
}
.Home .contact-container-form .btn {
  background-color: rgb(247, 93, 93);
  border: none;
}
.Home .contact-container-form .btn-holder {
  text-align: center;
}
.Home .contact-container-form .btn-holder .btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

@media (max-width: 576px) {
  .Home .title-colour {
    margin-top: 0px;
    padding-right: 0px;
  }

  .Home .line-animation {
    display: none;
  }
  .Home .arrow-style {
    display: none;
  }
  .Home .portal-style .overlay-text {
    top: 25%;
  }
  .Home .portal-style .overlay-text h3 {
    font-size: 45px;
  }
  .Home .portal-style .overlay-text h4 {
    font-size: 35px;
  }
  .Home .portal-style .overlay-text .btn {
    padding: 8px 12px;
    font-size: 16px;
  }

  .Home .container-fluid {
    width: 100%;
  }
  .Home .image-fill {
    display: none;
  }
  .Home .compliance-image {
    display: none;
  }
  .Home .break-line {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
}

@media (max-width: 768px) {
  .Home .title-colour {
    margin-top: 0px;
    padding-right: 0px;
  }

  .Home .arrow-style svg {
    display: none;
  }

  .Home .image-fill {
    padding-right: 0px !important;
    height: 365px;
  }
  .Home .compliance-image img {
    width: 277px;
    margin-top: 45px;
  }

  .Home .full-width .compliance-box .special-text {
    font-size: 20px;
  }

  .Home .full-width .compliance-box .special-text-bold {
    font-size: 40px;
    font-weight: 700;
  }
  .Home .portal-style {
    height: 346px;
  }
  .Home .compliance-image {
    max-width: none;
    flex: 0 0;
  }
}

@media (max-width: 992px) {
  .Home .title-colour {
    margin-top: 0px;
    padding-right: 0px;
  }

  .Home .line-animation {
    display: none;
  }
  .Home .arrow-style svg {
    display: none;
  }

  .Home .arrow-style p {
    display: none;
  }
  .Home .image-fill {
    padding-right: 0px;
  }
}

@media (min-width: 1200px) {
}
