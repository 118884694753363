.Service {
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  color: rgb(167, 167, 167);
  padding-bottom: 3%;
}
.Service .line-divider {
  height: 250px;
  border-left: 3px solid rgb(211, 211, 211);
}
.Service .services .service-heading h1 {
  font-size: 10vw;
  font-family: "Lato", sans-serif;
  color: rgb(167, 167, 167);
  padding-top: 25px;
}
.Service .service-line {
  width: 100px;
  border-bottom: 3px solid rgb(167, 167, 167);
}
.Service .purpose {
  color: rgb(167, 167, 167);
}
.Service .purpose p {
  font-size: 18px;
}
.Service .services .title-underline {
  width: 10%;
  border-bottom: 3px solid rgb(247, 93, 93);
}

.Service .title-spacing {
  padding-top: 80px;
  color: rgb(247, 93, 93);
}
.Service .underline-category {
  width: 5%;
  border-bottom: 3px solid rgb(247, 93, 93);
  margin-bottom: 50px;
  padding-top: 5px;
}
.Service .flex-container {
  background-color: rgb(247, 93, 93);
}
.Service .card-text {
  color: rgb(167, 167, 167);
}

.Service .works-heading {
  padding-top: 5%;
  padding-bottom: 50px;
}
.Service .how-box-colour h2 {
  color: rgb(247, 93, 93);
}
.Service .who-colour-box h2 {
  color: rgb(13, 175, 159);
}
.Service .how-box-colour {
  border-right: 3px solid rgb(167, 167, 167);
}
.Service .how-paragraph,
.who-paragraph {
  padding-top: 30px;
}
.Service .how-paragraph li,
.who-paragraph li {
  padding-bottom: 20px;
}
.Service .colour-separator {
  border-left: 4px solid rgb(13, 175, 159);
}
.how-heading {
  margin-top: 50px;
  margin-bottom: 50px;
}
.Service .for-underline2 {
  width: 10%;
  border-bottom: 3px solid rgb(13, 175, 159);
}
.works-heading .for-underline {
  width: 10%;
  border-bottom: 3px solid rgb(247, 93, 93);
}

.mini-line {
  width: 12%;
  border-bottom: 3px solid rgb(247, 93, 93);
}
.med-style {
  height: 406px;
}
.final-underline {
  width: 50px;
  border-bottom: 3px solid rgb(13, 175, 159);
  margin-bottom: 50px;
}

.Service .green {
  color: rgb(13, 175, 159);
}

.Service .help-paragraph {
  padding-right: 30px;
  line-height: 1.6;
}

@media (max-width: 576px) {
  .Service .line-divider {
    display: none;
  }
  .Service .purpose {
    margin-top: 50px;
  }
  .Service .how-box-colour {
    border-right: none;
    padding-right: 0px !important;
  }
  .Service .who-colour-box {
    padding-left: 15px !important;
  }
  .Service .how-colour-box .list-style ul {
    padding-right: 0px !important;
  }
}

@media (max-width: 768px) {
  .Service .line-divider {
    display: none;
  }
  .Service .purpose {
    margin-top: 50px;
  }
}

@media (max-width: 992px) {
  .Service .line-divider {
    display: none;
  }
}
