.itemDetail {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.itemDetail .grey {
  background-color: rgba(235, 235, 235, 0.4);
}

.itemDetail .item-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 41px;
  padding-left: 0px;
  padding-right: 0px;
}

.itemDetail .item-image-wrapper {
  width: 80%;
  position: relative;
}

.itemDetail .item-image {
  display: block;
  width: 100%;
}

.itemDetail .expiring-banner {
  background-color: rgb(255, 230, 0);
  padding: 7px 0 7px 0;
  width: 100%;
  z-index: 1;
  position: absolute;
  bottom: 5%;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
}

.itemDetail .expiring-banner h4 {
  font-weight: 300;
}

.itemDetail .expiring-banner span {
  font-weight: 700;
  font-size: 23px;
}

.itemDetail .color-container {
  background-color: rgb(233, 231, 231);
}

.itemDetail .item-description {
  padding-right: 100px;
}

.itemDetail .product-name {
  font-family: "Lato", sans-serif;
  background-color: rgb(247, 93, 93);
  color: rgb(255, 255, 255);
  padding: 5px 10px 6px 0;
  margin: 4% 0 2% 0;
  width: 30%;
  text-align: right;
}

.itemDetail .item-description .product-info {
  color: rgb(112, 112, 112);
  text-align: right;
}

.itemDetail .item-description .product-info h3 {
  font-size: 2.9rem;
}

.itemDetail .item-description .product-info p {
  font-weight: 700;
}

.itemDetail .item-description .product-description {
  color: rgb(112, 112, 112);
  padding-top: 20px;
  text-align: justify;
  padding-left: 50px;
  font-size: 18px;
}

.itemDetail .icon-color {
  color: rgb(247, 93, 93);
}

.itemDetail .expiry-display p {
  background-color: rgb(247, 93, 93);
  padding: 10px;
  width: 200px;
  color: rgb(255, 255, 255);
  border-radius: 25px;
  margin-bottom: 0px !important;
  text-align: center;
}

.itemDetail .request-button {
  background-color: rgb(13, 175, 159);
  padding: 10px 85px 10px 85px;
  margin-top: 25px;
  border: none !important;
  color: rgb(255, 255, 255);
  border-radius: 25px;
}

.itemDetail .request-button-wrapper {
  display: flex;
  justify-content: center;
}

.itemDetail .items-button {
  background-color: rgb(247, 93, 93);
  padding: 10px 50px 10px 50px;
  border: none;
  border-radius: 0px;
  color: rgb(255, 255, 255);
}

.itemDetail .quanity-counter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.itemDetail .item-quantity {
  padding-left: 15px;
  text-align: center;
}

.itemDetail .items-button-wrapper {
  padding-top: 43px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.itemDetail .counter-style {
  text-align: center;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid gray;
  width: 140px;
}

.itemDetail .quanity-counter p {
  margin-bottom: 0;
  margin-top: 5px;
}

.itemDetail .sidenav-style {
  padding-left: 0px;
  padding-right: 0px;
}

.itemDetail .items-style {
  padding-left: 0;
  padding-right: 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .itemDetail .product-name {
    width: 100%;
  }
  .itemDetail .item-image-wrapper {
    width: 90% !important;
  }

  .itemDetail .item-description {
    margin-left: auto;
    margin-right: auto;
    padding-right: 0px;
    padding-left: 0px;
    width: 100%;
    padding-top: 0px !important;
  }
  .itemDetail .item-image-container {
    margin-top: 0px;
  }

  .itemDetail .product-name {
    margin: 6% 0 0 0;
  }
  .itemDetail .sidenav-style {
    margin-right: 15px;
    margin-left: 15px;
  }
  .itemDetail .item-description {
    padding: 0px !important;
  }

  .itemDetail .item-description .product-description {
    padding-left: 20px !important;
    padding-right: 20px;
  }
  .itemDetail .item-description .product-info {
    margin-right: 22px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 992px) {
  .itemDetail .product-name {
    width: 70%;
  }
  .itemDetail .grey {
    padding-top: 0px !important;
  }

  .itemDetail .color-container {
    display: flex;
    flex-direction: column;
  }

  .itemDetail .item-image-wrapper {
    width: 80%;
    padding-top: 10px;
  }

  .itemDetail .itemDetails-wrapper {
    display: flex;
    justify-content: center;
  }

  .itemDetail .item-description .product-description {
    padding-left: 0px;
  }
  .itemDetail .item-description {
    padding-right: 30px;
  }

  .itemDetail .quanity-counter {
    padding-top: 20px !important;
  }
  .itemDetail .items-button-wrapper {
    padding: 8%;
    margin-bottom: 5%;
  }
}
