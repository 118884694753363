.DashboardPage {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.DashboardPage .grey {
  background-color: rgba(235, 235, 235, 0.4);
}

.DashboardPage .sidenav-style {
  padding-left: 0px;
}

@media only screen and (max-width: 900px) {
  .DashboardPage .sidenav-style {
    padding-left: 15px;
  }
  .DashboardPage .grey {
    background-color: rgba(235, 235, 235, 0.4);
    padding-top: 0px !important;
  }
}
