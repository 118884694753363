.card-expiration .expiring-banner {
  background-color: rgb(255, 230, 0) !important;
  padding: 4px;
  width: 100%;
  z-index: 1;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
}

.items-button {
  background-color: rgb(247, 93, 93) !important;
  border-color: rgb(223, 71, 71);
}
