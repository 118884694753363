.Loginpage h1 {
  font-family: "lato", sans-serif;
  font-size: 22px;
  color: black;
}

.Loginpage {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 11%;
}

.Loginpage .btn {
  background-color: rgb(247, 93, 93) !important;
  border: none !important;
  text-align: center;
}

.Loginpage .btn a {
  color: white;
}

.Loginpage .btn a :hover {
  color: white;
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .Login {
    margin-top: 20px;
  }

  .Login {
    margin-right: 0px;
  }

  .Login .signup {
    margin-top: 29px;
  }
}
