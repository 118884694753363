.Login .btn {
  background-color: rgb(247, 93, 93) !important;
  border: none !important;
}

.Login .btn a {
  color: white;
}

.Login .btn a :hover {
  color: white;
  text-decoration: underline;
}

.Login {
  font-family: "proxima-nova", sans-serif;
  margin-top: 60px;
  margin-right: 100px;
}

@media only screen and (max-width: 768px) {
  .Login {
    margin-top: 20px;
  }
}
