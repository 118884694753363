.Signupbtn .btn a {
  color: white;
}

.Signupbtn .btn a :hover {
  color: white;
  text-decoration: underline;
}
.signup h1 {
  font-family: "lato", sans-serif;
  font-size: 22px;
  color: black;
}
